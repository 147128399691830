/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */

.hover-actions {
  display: none;
  position: absolute;
  &.show {
    display: inline-block;

    & + .hover-hide {
      display: none;
    }
  }
}

a[href]{
  &.bg-200{
    @include hover-focus{
      background-color: $gray-300 !important;
    }
  }
}

// Used in search box
.hover-primary{
  @include hover-focus{
    color: var(--#{$prefix}primary) !important;
  }
}

.hover-danger{
  @include hover-focus{
    color: var(--#{$prefix}danger) !important;
  }
}

.hover-shadow {
  @include hover-focus {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  }
}
.hover-actions-trigger {
  position: relative;

  // Style for email Inbox
  .inbox-link {
    color: inherit;
    text-decoration: none;
  }
  
  @include hover-focus {
    .hover-actions {
      z-index: 1;
      display: flex;
      &.btn{
        display: inline-block;
      }
    }
   
    .hover-hide {
      display: none;
    }
  }
}

// used in crm todo list
.hover-bg {
  @each $color, $value in $grays {
    &-#{$color} {
      &:hover {
        background-color:  var(--#{$prefix}gray-#{$color}) !important;
      }
    }
  }
}

.hover {
  @each $color, $value in $grays {
    &-#{$color} {
      &:hover {
        color:  var(--#{$prefix}gray-#{$color}) !important;
      }
    }
  }
}